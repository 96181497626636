<template>
	<div>
		<v-fade-transition hide-on-leave>
			<v-data-table
							ref="table"
							:key="renderKey"
							v-bind="$attrs"
							v-if="!mobileresponsive || (mobileresponsive && $vuetify.breakpoint.mdAndUp)"
							:headers="headers"
							:items="items"
							must-sort
							:loading="false"
							class="elevation-1"
							:hide-default-footer="hideActions || items.length === 0"
							hide-default-header
							:sort-by.sync="pagination.sortBy"
							:sort-desc.sync="pagination.descending"
							:items-per-page.sync="pagination.perPage"
							:page.sync="pagination.pageNum"
							:server-items-length="pagination.totalRows"
							disable-sort
							:disable-pagination="disablePagination"
							:footerProps="defaultFooterProps"
							:dense="dense"
							:custom-sort="sortFn || defaultSortFn"
			>

				<template v-slot:header="">
					<thead class="v-data-table-header">
						<tr>
							<th v-if="trackSelectedItems && items.length > 0" :style="{width: '1%'}" class="column text-center">
								<v-checkbox :dense="dense" class="table-checkbox" key="CheckAll" v-model="hasSelectedAllItems" @change="onChangeSelectAll" hide-details color="accent"></v-checkbox>
							</th>
							<th
											v-for="header in headers"
											:key="`${header.text}_${header.value}`"
											:class="[
												'column',
												'text-left',
												header.class,
												header.sortable !== false? 'sortable' : '',
												pagination.descending ? 'desc' : 'asc',
												(pagination.sortBy || []).includes(header.value) ? 'active' : ''
											]"
											:style="{width: header.width || 'auto'}"
											@click="setSort(header)"
							>
								{{ header.text }}
								<v-icon v-if="header.sortable !== false && (pagination.sortBy || []).includes(header.value)" small>{{pagination.descending ? 'mdi-sort-descending' : 'mdi-sort-ascending'}}</v-icon>

								<Tooltip v-if="header.tooltip" :text="header.tooltip.text">
									<v-icon v-bind="header.tooltip.iconProps">{{header.tooltip.icon}}</v-icon>
								</Tooltip>
							</th>
						</tr>
						<tr class="v-datatable__progress dv-datatable-progress ma-0 pa-0">
							<th :colspan="headers.length + (trackSelectedItems ? 1 : 0)" class="column ma-0 pa-0" style="height: auto;">
								<v-progress-linear v-if="loading" indeterminate height="4"/>
							</th>
						</tr>
					</thead>
				</template>

				<template v-slot:body="{items}">
					<tbody>
					<slot name="input-row">
					</slot>

						<tr v-for="(item, index) in items" :key="index" :class="getTableRowClasses(item)" @click="handleOnClickRow(item, index, $event)">
							<td v-if="trackSelectedItems" class="select-item" :style="{width: '1%'}">
								<v-checkbox
												color="accent"
												class="table-checkbox"
												:dense="dense"
												:disabled="!canSelectItem(item)"
												:key="getSelectedItemKey(item)" v-model="item._selected" hide-details @change="onChangeSelectItem(item)"></v-checkbox>
							</td>
							<slot name="table-row" v-bind="{item, index, selected: item._selected === undefined ? 'UNDEFINED' : item._selected}"></slot>
						</tr>

						<slot name="footer"></slot>

					<slot name="no-data" v-if="!items || items.length === 0">

					</slot>
					</tbody>
				</template>
			</v-data-table>
		</v-fade-transition>

		<Loading :key="2" v-if="mobileresponsive && $vuetify.breakpoint.smAndDown" :visible="loading"/>

		<v-fade-transition hide-on-leave>
			<v-data-iterator
							:key="1"
							v-if="mobileresponsive && $vuetify.breakpoint.smAndDown && !loading"
							v-bind="$attrs"
							:items="items"
							content-tag="v-layout"
							column
			>
				<v-col
								class="mt-2"
								slot="item"
								slot-scope="props"
								cols="12"
				>
					<v-card outlined class="mb-2">
						<v-row dense>
							<v-col cols="6">
								<v-list dense>
									<v-list-item v-for="(header, index) in headers" :key="index">
										<v-list-item-content class="font-weight-bold">{{header.text}}</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-col>
							<v-col cols="6">
								<v-list dense>
									<slot name="mobile-row" v-bind="props"></slot>
								</v-list>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-data-iterator>
		</v-fade-transition>

		<BottomToolbar v-if="visibleBatchButtons.length > 0" colsRight="8" colsLeft="4">
			<template v-for="item in [{direction: 'left', buttons: leftBatchButtons}, {direction: 'right', buttons: rightBatchButtons}]">
				<template :slot="item.direction" v-if="item.buttons.length > 0">
					<MassButtonsHandler
							:buttons="item.buttons"
							:any-button-enabled="item.buttons.filter((item) => !(item.disabled ? item.disabled(selectedItems) : false)).length > 0"
							:menuButtonText="`Med markerade ${selectedItems.length > 0 ? `(${selectedItems.length})` : '' }`"
							:isLoading="isSendingBatchRequest"
					>
						<template v-slot:button="item">
							<v-btn
									:color="item.color || 'primary'"
									:class="item.class"
									:disabled="item.disabled ? item.disabled(selectedItems) : false"
									:loading="isSendingBatchRequest"
									depressed
									@click="onHandleBatch(item)"
							>
								<v-icon v-if="item.icon" class="mr-2">{{item.icon}}</v-icon> {{item.text}} <span v-if="!(item.disabled ? item.disabled(selectedItems) : false)" class="ml-1">({{item.count ? item.count(selectedItems) : selectedItems.length}})</span>
							</v-btn>
						</template>
					</MassButtonsHandler>
				</template>
			</template>
		</BottomToolbar>
	</div>
</template>

<script>
import {mapActions} from 'vuex';


import {assign, has, pick, find, findIndex, filter, map, includes, sortBy} from 'lodash';

import PaginationHelper   from '../lib/PaginationHelper.js';
import BottomToolbar      from './BottomToolbar.vue';
import Loading            from './Loading.vue';
import MassButtonsHandler from './MassButtonsHandler.vue';
import Tooltip            from './Tooltip.vue';

export default {
	name: 'DataTable',

	props: {
		'items': {
			type:     Array,
			required: true,
		},
		'pagination': {
			type:    Object,
			default: () => new PaginationHelper({perPage: -1}),
		},
		'headers': {
			type:     Array,
			required: true,
		},
		'loading': {
			type:    Boolean,
			default: false,
		},
		'mobileresponsive': {
			type:    Boolean,
			default: false,
		},
		'hide-actions': {
			type:    Boolean,
			default: false,
		},
		'trackSelectedItems': {
			type:    Boolean,
			default: false,
		},
		'canSelectItem': {
			type:    Function,
			default: () => true,
		},
		'selectedItemsKeys': {
			type:    Array,
			default: () => [],
		},
		'getTableRowClasses': {
			type:    Function,
			default: () => '',
		},
		'onClickRow': {
			type:    Function,
			default: () => '',
		},

		'batchButtons': {
			type:    Array,
			default: () => [],
		},

		'dense': {
			type:    Boolean,
			default: true,
		},

		'sortFn': {
			type:    Function,
			default: null,
		},

		'disablePagination': {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		selectedItems:         [],
		hasSelectedAllItems:   false,
		isSendingBatchRequest: false,
		defaultFooterProps:    {
			itemsPerPageOptions: PaginationHelper.itemsPerPageOptions,
		},
		renderKey: (Math.random() * 10000).toString(),
	}),

	watch: {
		'pagination.searchTerm'(value) {
			this.onPaginationChanged(value ? 500 : 100, true);
		},
		'pagination.sortBy':     'onPaginationChanged',
		'pagination.descending': 'onPaginationChanged',
		'pagination.perPage':    'onPaginationChanged',
		'pagination.pageNum':    'onPaginationChanged',

		items(newValue) {
			if(this.trackSelectedItems) {
				for(const item of newValue) {
					if(!has(item, '_selected')) {
						item._selected = !!find(this.selectedItems, pick(item, this.selectedItemsKeys));
					}
				}
			}

			this.hasSelectedAllItems = newValue.filter((item) => !item._selected).length === 0;

			this.$emit('itemsChanged');
		},

		trackSelectedItems() {
			this.selectedItems = [];

			if(this.trackSelectedItems) {
				if(this.selectedItemsKeys.length === 0) {
					throw new Error('Prop trackSelectedItems cannot be used without specifying selectedItemsKeys!');
				}
			}
		},
	},

	methods: {
		...mapActions(['confirmDialog']),

		setSelectedItems(items, {triggerTableReRender = true} = {}) {
			if(!this.trackSelectedItems) {
				return;
			}

			this.selectedItems = items;

			for(const item of items) {
				if(item._selected === false) {
					item._selected = true;
				}
			}

			if(triggerTableReRender) {
				this.triggerTableReRender();
			}
		},

		triggerTableReRender() {
			setTimeout(() => {
				this.renderKey = (Math.random() * 10000).toString();
			}, 1);
		},

		setSort(header) {
			if(header.sortable === false) {
				return;
			}

			this.pagination.setSortField(header.value);
		},

		onPaginationChanged(delay = 100, resetToPageNum1 = false) {
			clearTimeout(this.paginationChangedTimer);

			this.paginationChangedTimer = setTimeout(() => {
				if(resetToPageNum1 === true && this.pagination.pageNum !== 1) {
					//Recursively back to onPaginationChanged
					this.pagination.pageNum = 1;
				} else {
					this.$emit('change:pagination');
				}
			}, delay);
		},

		defaultSortFn(items, [fieldName], [isDescending]) {
			const sorted = sortBy(items, fieldName);

			return isDescending ? sorted.reverse() : sorted;
		},

		onHandleBatch(batchButton) {
			const selectedItemsCount = batchButton.overrideHandler && batchButton.count
				? batchButton.count(this.selectedItems)
				: this.selectedItems.length

			if(selectedItemsCount === 0) {
				return;
			}

			this.$tryCatch({
				task: async () => {
					let data = this.selectedItems.map((item) =>
						assign({}, batchButton.sendCompleteItem ? item : pick(item, this.selectedItemsKeys), batchButton.set)
					);

					if(batchButton.preProcessData) {
						data = batchButton.preProcessData(data);
					}

					this.isSendingBatchRequest = true;

					if(batchButton.overrideHandler) {
						await batchButton.overrideHandler(data);
						// this.clearSelectedItems(batchButton);
					} else {
						const {requestMethod = 'PUT'} = batchButton;
						const responseData = await this.$http[requestMethod.toLowerCase()](batchButton.requestURL, data);

						if(batchButton.onSuccess) {
							batchButton.onSuccess(responseData)
						}

						this.clearSelectedItems(batchButton);
					}
				},

				finally: () => {
					this.isSendingBatchRequest = false;
				},
			});
		},

		getSelectedItems() {
			return this.selectedItems;
		},

		clearSelectedItems({askToKeepBatching = false} = {}) {
			for(const item of this.items) {
				item._selected = false;
			}

			if(askToKeepBatching) {
				const previousSelectedItemsKeys = map(this.selectedItems, this.getSelectedItemKey);

				if(previousSelectedItemsKeys.length > 0) {
					this.$once('itemsChanged', () => {
						// this.confirmDialog({
						// 	title: 'Massbearbetning',
						// 	text: `Vill du fortsätta massbearbeta de tidigare markerade posterna?`,
						// 	cancelText: 'Nej',
						// 	confirmText: 'Ja',
						// 	onConfirm: () => {
						const itemsToSelect = filter(this.items, (item) => includes(previousSelectedItemsKeys, this.getSelectedItemKey(item)));

						for(const item of itemsToSelect) {
							this.toggleItemSelection(item);
						}
						// this.hideConfirmDialog();
						// 	},
						// 	onCancel: () => this.hideConfirmDialog(),
						// });
					});
				}
			}

			this.selectedItems = [];
			this.hasSelectedAllItems = false;
		},

		handleOnClickRow(item, index, event) {
			//Do not trigger onClickRow when clicking on an input field such as a checkbox
			if((event.target.className || '').includes('input')) {
				return;
			}

			this.onClickRow(item, index, event);
		},

		getSelectedItemKey(item) {
			return JSON.stringify(pick(item, this.selectedItemsKeys));
		},

		onChangeSelectAll(value) {
			for(const item of this.items) {
				const canSelectItem = this.canSelectItem(item);
				const existingItemIndex = findIndex(this.selectedItems, pick(item, this.selectedItemsKeys));

				item._selected = canSelectItem ? value : false;

				if(value === true && canSelectItem && existingItemIndex < 0) {
					this.selectedItems.push(item);
					this.$emit('selectedItemsChanged');
				}

				if(!item._selected && existingItemIndex >= 0) {
					this.selectedItems.splice(existingItemIndex, 1);
					this.$emit('selectedItemsChanged');
				}
			}
		},

		onChangeSelectItem(item) {
			this.toggleItemSelection(item, true);
		},

		toggleItemSelection(item, isFromEventHandler = false) {
			const keysAsObj = pick(item, this.selectedItemsKeys);

			//When from eventHandler the item's value of _selected will already have been updated by v-model
			//so we have to handle it in a reverse order here.
			const isSelected = isFromEventHandler
				? !item._selected
				: item._selected;

			if(isSelected) {
				const index = findIndex(this.selectedItems, keysAsObj);

				if(index >= 0) {
					this.selectedItems.splice(index, 1);
					this.$emit('selectedItemsChanged');
				}
				item._selected = false;
			} else {
				item._selected = true;
				this.selectedItems.push(item);
				this.$emit('selectedItemsChanged');
			}
		},
	},

	computed: {
		visibleBatchButtons() {
			return this.batchButtons.filter((item) => item.show);
		},

		leftBatchButtons() {
			return this.visibleBatchButtons.filter((item) => item.direction === 'left');
		},

		rightBatchButtons() {
			return this.visibleBatchButtons.filter((item) => item.direction !== 'left');
		},
	},

	components: {
		Tooltip,
		MassButtonsHandler,
		Loading,
		BottomToolbar,
	},
}
</script>

<style scoped>
/**
Temporary due to issue in vuetify:
https://github.com/vuetifyjs/vuetify/issues/5025
*/
	::v-deep(td.text-right > .v-small-dialog > .v-menu__activator) {
		display: block;
	}

	::v-deep(td.text-right > .v-small-dialog > .v-menu__activator > a) {
		display: block;
		line-height: 47px;
	}

	::v-deep(thead) {
		border-bottom: 1px solid #dcdcdc;
		background-color: #f1f1f1;
	}

	::v-deep(.theme--dark thead) {
		border-bottom: 1px solid #dcdcdc;
		background-color: #212121;
	}

	::v-deep(thead > tr > th) {
		font-size: 13px;
	}

	::v-deep(td.text-center .v-input--checkbox) {
		align-items: center;
		justify-content: center;
	}

	::v-deep(th.text-center .v-input--checkbox) {
		align-items: center;
		justify-content: center;
	}

	::v-deep(.v-input--checkbox) {
		margin-top: 0;
	}

	::v-deep(.v-datatable__actions) {
		background-color: #212121 !important;
	}

	::v-deep(.theme--dark .v-datatable__actions) {
		background-color: #212121 !important;
	}

	::v-deep(.input-row) {
		background-color: #f9f9f9;
	}

	::v-deep(.theme--dark .input-row) {
		background-color: #303030;
	}

	::v-deep(thead > tr:nth-child(2)) {
		background-color: #f9f9f9;
	}

	::v-deep(.theme--dark thead > tr:nth-child(2)) {
		background-color: #303030;
	}

	::v-deep(thead > tr:nth-child(2) .v-input) {
		margin-top: 0;
	}

	::v-deep(	.isinactive > td:not(.select-item):not(.tools)) {
		opacity: .7;
	}

	::v-deep(.v-datatable__actions .v-btn) {
		margin-top: 0;
		margin-bottom: 0;
	}

	::v-deep(.v-datatable__actions__select .v-select) {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 15px;
	}

	::v-deep(table.v-table thead tr) {
		height: 49px;
	}

	::v-deep(.v-datatable__progress:not(.dv-datatable-progress)) {
		display: none !important;
	}

	::v-deep(th.text-right) {
		text-align: right !important;
	}

	::v-deep(th.text-center) {
		text-align: center !important;
	}

	::v-deep(.input-row td) {
		padding-bottom: 5px;
	}
	::v-deep(.input-row td .v-text-field) {
		padding-top: 0;
	}

	::v-deep(.input-row td .v-text-field.v-input--dense:not(.v-text-field--outlined):not(.v-text-field--solo) input) {
		padding: 8px 0 8px;
	}

	/*TODO: Remove this and make a proper custom default footer */
	::v-deep(.v-data-footer__select) {
		height: 33px;
	}
</style>

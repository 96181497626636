/**
 * Created by simon on 2018-11-30.
 */

import moment    from 'moment';
import Validate  from '../lib/Validate.js';
import BaseModel from './BaseModel.js';

class FtgInfo extends BaseModel {
	static get propertyMap() {
		return {
			FtgId: {
				label:        'Företags-ID',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.maxLength(15),
				],
				helpTextId:           'ftginfo-FtgId',
				helpTextIdIgnorePath: true,
			},

			FtgKey: {
				label:        'Företagsnyckel',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			Namn: {
				label:        'Företagsnamn',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.maxLength(40),
				],
				helpTextId:           'ftginfo-Namn',
				helpTextIdIgnorePath: true,
			},

			Orgnr: {
				label:        'Organisationsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.validOrgNr,
				],
			},

			Adress1: {
				label:        'Adress',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			Adress2: {
				label:        'Adress',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			PostNr: {
				label:        'Postnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},

			PostOrt: {
				label:        'Ort',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(30),
				],
			},

			LandAdr: {
				label:        'Land',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			Telefon: {
				label:        'Telefon',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(20),
				],
			},

			Epost: {
				label:        'E-post',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
					Validate.validEmail,
				],
			},

			Hemsida: {
				label:        'Hemsida',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(255),
				],
			},

			Kontakt: {
				label:        'Kontaktperson',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			LoneAnsv: {
				label:        'Löneansvarig',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			AttestAnsv: {
				label:        'Attestansvarig',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(40),
				],
			},

			UseKalendarie: {
				label:        'Använd kalendariet',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalendarieStart: {
				label:        'Startdatum för kalendarie',
				type:         String,
				defaultValue: `${(new Date()).getFullYear()}-01-01`,
				rules:        [
					Validate.validDate,
				],
				isDateField: true,
			},

			UseLSS: {
				label:        'Använd LSS',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BrukarNum: {
				label:        'Numerisk sortering av brukarnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AnstNum: {
				label:        'Numerisk sortering av anställningsnummer',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			UseNewAnstNrSuggestion: {
				label:        'Föreslå nästa anställningsnummer när ny anställd ska skapas',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			FromNewAnstNrSuggestion: {
				label:        'Anställningsnummer fr.o.m',
				type:         Number,
				defaultValue: 0,
				rules:        [
					Validate.numberGreaterThan(-1),
				],
			},

			TomNewAnstNrSuggestion: {
				label:        'Anställningsnummer t.o.m',
				type:         Number,
				defaultValue: 1,
				rules:        [
					Validate.numberGreaterThan(-1),
				],
			},

			UseAvdelning: {
				label:        'Använd avdelningsregister',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			LSSKollAvtal: {
				label:        'Kollektivavtal',
				type:         String,
				defaultValue: 'NEJ',
				rules:        [],
			},

			LSSKollAvtEgt: {
				label:        'Eget kollektivavtal',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(30),
				],
			},

			LSSKontaktNamn: {
				label:        'Kontaktperson',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(65),
				],
			},

			LSSKontaktTel: {
				label:        'Telefon',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(20),
				],
			},

			UseBilPrivat: {
				label:        'Milersättning privatbil',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BilPrivat: {
				label:        'Ersättningsbelopp privatbil',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			UseBilBensin: {
				label:        'Milersättning förmånsbil',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BilBensin: {
				label:        'Ersättningsbelopp förmånsbil',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			UseBilDiesel: {
				label:        'Milersättning förmånsbil (diesel)',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BilDiesel: {
				label:        'Ersättningsbelopp förmånsbil (diesel)',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			UseBilEl: {
				label:        'Milersättning förmånsbil (el)',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BilEl: {
				label:        'Ersättningsbelopp förmånsbil (el)',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			AvrundaKontering: {
				label:        'Avrundning av beräkning/kontering',
				type:         Number,
				defaultValue: 2,
				rules:        [
					Validate.validInteger,
				],
			},

			AvrundaSumma: {
				label:        'Avrundning av löneartsrad summa',
				type:         Number,
				defaultValue: 2,
				rules:        [
					Validate.validInteger,
				],
			},

			AvrundaTotal: {
				label:        'Avrunding av belopp att utbetala',
				type:         Number,
				defaultValue: 0,
				rules:        [
					Validate.validInteger,
				],
			},

			Momspliktig: {
				label:        'Företaget är momspliktigt',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			StartBokfAr: {
				label:        'Startmånad räkenskapsår',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			OpenResEnhet1: {
				label:        'Använd resultatenhet 1',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			OpenResEnhet2: {
				label:        'Använd resultatenhet 2',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			OpenResEnhet3: {
				label:        'Använd resultatenhet 3',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			OpenResEnhet4: {
				label:        'Använd resultatenhet 4',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			OpenResEnhet5: {
				label:        'Använd resultatenhet 5',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			OpenResEnhet6: {
				label:        'Använd resultatenhet 6',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			ResEnhet1Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 1',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet2Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 2',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet3Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 3',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet4Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 4',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet5Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 5',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet6Namn: {
				label:        'Namn på resultatenheten',
				type:         String,
				defaultValue: 'Resultatenhet 6',
				rules:        [
					Validate.maxLength(15),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			RegisterResEnh1: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegisterResEnh2: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegisterResEnh3: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegisterResEnh4: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegisterResEnh5: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegisterResEnh6: {
				label:        'Använd register',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh1: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh2: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh3: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh4: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh5: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			RegResEnh6: {
				label:        'Får registreras',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh1: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh2: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh3: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh4: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh5: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			VisaResEnh6: {
				label:        'Visa på registreringsrad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				helpTextId:   'bokforingsettings-resenheter',
			},

			ResEnhet1Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet2Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet3Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet4Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet5Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			ResEnhet6Std: {
				label:        'Standardkontering',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-resenheter',
			},

			KalUseLonReady: {
				label:        'Anställd ska godkänna sina tider',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalLonReadyTyp: {
				label:        'Vad ska den anställde godkänna?',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			KalLockLonReady: {
				label:        'Lås godkända dagar',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalUseAttest: {
				label:        'Attestansvarig ska attestera löneunderlaget',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalAttestTyp: {
				label:        'Vad ska attestansvarig attestera?',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			KalLockAttest: {
				label:        'Lås attesterade dagar',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalUseAttestBru: {
				label:        'Endast brukare kan attestera sina egna tider',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalUseNollPostAsDag: {
				label:        'Tomma tidrader ska tolkas som 1 kalenderdag',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseFKSignering: {
				label:        'Signera FK3059 med BankID',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			VisaLBFrDgr: {
				label:        'Antal dagar innan utbetalning som anställd får se sitt lönebesked',
				type:         Number,
				defaultValue: 3,
				rules:        [],
			},

			KalUseStartdag: {
				label:        'Använd bruten kalendermånad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KalStartDag: {
				label:        'Startdag för bruten kalendermånad',
				type:         Number,
				defaultValue: 0,
				rules:        [
					(item) => item.context.KalUseStartdag ? Validate.numberBetween(1, 31)(item) : true,
				],
			},

			KalLockLonLock: {
				label:        'Lås dagar överförda till lön',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			KalLockKalArtDat: {
				label:        'Lås rapportering efter sista rapporteringsdag',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			Bankgiro: {
				label:        'Bankgironummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(20),
				],
			},

			KopplaBank: {
				label:        'Överföring till bank',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BankNamn: {
				// label: 'Filformat till bank',
				label:        'Överföring till bank',
				type:         String,
				defaultValue: 'BGC',
				rules:        [],
			},

			BankFtgNr: {
				label:        'Företagsnummer hos bank',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},

			BankKundId: {
				label:        'Kund-ID',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			BankClearNr: {
				label:        'Skicka clearingnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BankPersonNr: {
				label:        'Kontoförande bank',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BankIBAN: {
				label:        'Bankkonto, IBAN',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(34),
				],
			},
			BankBIC: {
				label:        'Bankkonto, BIC',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validBICLength(),
				],
			},
			BankIsoStrictDate: {
				label:        'Debiteringsdag',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			BankIsoSigner: {
				label:        'Avsändar-ID',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			BankIsoSignerCust: {
				label:        'Schemakod',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BankIsoKundIDCust: {
				label:        'Schemakod',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SkattTab: {
				label:        'Normal skattetabell',
				type:         Number,
				defaultValue: 32,
				rules:        [],
			},

			ScbArbstKU: {
				label:        'Arbetsställenr',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(6),
				],
			},

			ScbArbstCFAR: {
				label:        'Arbetsställenr (SCB-CFAR)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(8),
				],
			},

			KUSpecNr: {
				label:        'Specifikationsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.maxLength(32),
				],
			},

			UseFirstAnstNr1: {
				label:        'Använd växa stöd (1)',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				onChange(model) {
					if(model.UseFirstAnstNr1) {
						if(!model.FirstAnstTom1) {
							model.FirstAnstTom1 = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
						}
					} else {
						model.FirstAnstTom1 = '';
					}
				},
			},

			FirstAnstNr1: {
				label:        'Första anställd (1)',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			FirstAnstTom1: {
				label:        'Växa stöd (1) t.o.m.',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.UseFirstAnstNr1 ? Validate.required(item) : true,
				],
				isDateField: true,
			},

			UseFirstAnstNr2: {
				label:        'Använd växa stöd (2)',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
				onChange(value, model) {
					if(model.UseFirstAnstNr2) {
						if(!model.FirstAnstTom2) {
							model.FirstAnstTom2 = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
						}
					} else {
						model.FirstAnstTom2 = '';
					}
				},
			},

			FirstAnstNr2: {
				label:        'Första anställd (2)',
				type:         String,
				defaultValue: '',
				rules:        [],
			},

			FirstAnstTom2: {
				label:        'Växa stöd (2) t.o.m.',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.UseFirstAnstNr2 ? Validate.required(item) : true,
				],
				isDateField: true,
			},

			EjFastDriftst: {
				label:        'Ej fast driftställe i Sverige',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			ArbAvgGrans: {
				label:        'Använd 1000 kr gräns socialavgifter',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			ArbAvgRabattA: {
				label:        'Använd regionalt stöd',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			ArbAvgRabFoU: {
				label:        'Använd rabatt forskning & utveckling',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KostAvdrPrel: {
				label:        'Kostnadsavdrag påverkar preliminärskatt',
				type:         Boolean,
				defaultValue: true,
				rules:        [],
			},

			BelNamnP01: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP02: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP03: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP04: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP05: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP06: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP07: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP08: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP09: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP10: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP11: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP12: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP13: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP14: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP15: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP16: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP17: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP18: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP19: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelNamnP20: {
				label:        'Benämning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(25),
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP01: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP02: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP03: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP04: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP05: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP06: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP07: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP08: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP09: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP10: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP11: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP12: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP13: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP14: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP15: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP16: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP17: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP18: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP19: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			BelKodP20: {
				label:        'Formelkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					Validate.alphanumeric,
				],
				helpTextId: 'companysettings-puppgifter',
			},

			FordelaArbAvg: {
				label:        'Arbetsgivaravgifter',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			FordelaSemLon: {
				label:        'Beräknad semesterkostnad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			FordelaPension: {
				label:        'Försäkringar',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			FordelaMotkonto: {
				label:        'Motkontering',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraAmfAvgift: {
				label:        'Kontera Fora avgift',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraPension1: {
				label:        'Kontera försäkring 1 avgift',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraPension2: {
				label:        'Kontera försäkring 2 avgift',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraAntal: {
				label:        'Kontera med antal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraNaturaFrm: {
				label:        'Kontera naturaförmåner',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraPassiva: {
				label:        'Kontera passiva lönearter',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraBerSemLon: {
				label:        'Kontera semesterskuld',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraAvrSemLon: {
				label:        'Kontera avräkning semesterskuld',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KonteraBerArbAvg: {
				label:        'Kontera arbetsgivaravgift semesterskuld',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KtoIngMoms: {
				label:        'Ingående moms',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoUtgMoms: {
				label:        'Utgående moms',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkPrelSkatt: {
				label:        'Personalens källskatter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoUtkontant: {
				label:        'Kontant',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			BankKonto: {
				label:        'Bankkontonummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(34),
				],
			},

			KtoUtBankkonto: {
				label:        'Från bank',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoUtPostgiro: {
				label:        'Från plusgiro',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoAvrundning: {
				label:        'Öresavrundning',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAMFAvgift: {
				label:        'Fora avgifter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkAmfAvgift: {
				label:        'Fora avgifter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsPension1: {
				label:        'Försäkring 1',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsPension2: {
				label:        'Försäkring 2',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkPension1: {
				label:        'Pension 1',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkPension2: {
				label:        'Pension 2',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerSemArb: {
				label:        'Beräknad semesterskuld arbetare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerSemTjm: {
				label:        'Beräknad semesterskuld tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrSemArb: {
				label:        'Avräknad semesterskuld arbetare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrSemTjm: {
				label:        'Avräknad semesterskuld tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkBerSemLon: {
				label:        'Beräknad semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkBerArbAvg: {
				label:        'Beräknad arbetsgivaravgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerArbAvg: {
				label:        'Beräknad arbetsgivaravgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkAvrSemLon: {
				label:        'Avräknad semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrArbAvg: {
				label:        'Avräknad arbetsgivaravgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkAvrArbAvg: {
				label:        'Avräknad arbetsgivaravgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsArbAvgLon: {
				label:        'Arbetsgivaravgifter löner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},

			KtoSkArbGivAvg: {
				label:        'Arbetsgivaravgift',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsRedAvgLon: {
				label:        'Ungdomsavgifter löner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkArbGivRed: {
				label:        'Arbetsgivaravgift ungdomar',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsRedAvgFrm: {
				label:        'Ungdomsavgifter förmåner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsRedAvgSem: {
				label:        'Ungdomsavgifter semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsPenAvgLon: {
				label:        'Pensionärsavgifter löner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkArbGivPen: {
				label:        'Arbetsgivaravgift pensionär',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsPenAvgFrm: {
				label:        'Pensionärsavgifter förmåner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsPenAvgSem: {
				label:        'Pensionärsavgifter semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsSlfAvgLon: {
				label:        'Löneskatt löner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkSlfAvgift: {
				label:        'Särskild löneskatt',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},


			KtoKsSlfAvgFrm: {
				label:        'Löneskatt förmåner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},


			KtoKsSlfAvgSem: {
				label:        'Löneskatt semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsArbAvgFrm: {
				label:        'Arbetsgivaravgifter förmåner',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsArbAvgSem: {
				label:        'Arbetsgivaravgifter semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoObsKonto: {
				label:        'Observationskonto',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoMkNaturaFrm: {
				label:        'Motbokning naturaförmån',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoMkPassiva: {
				label:        'Motbokning passiva lönearter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			SpecaPerAvg: {
				label:        'Konto för socialavgift valbart per person',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaPerTyp: {
				label:        'Lön per personaltyp',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaArbGivAvgPerTyp: {
				label:        'Arbetsgivaravgift per personaltyp',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaFrmAvg: {
				label:        'Socialavgift för naturaförmån',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaSemLon: {
				label:        'Utbetald semesterlön',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaSktDek: {
				label:        'Avrundning & rabatt arbetsgivardeklaration',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaSlfLon: {
				label:        'Lön med olika arbetsgivaravgift',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SpecaSjkLon: {
				label:        'Sjuklön',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KtoJuArbAvg: {
				label:        'Justering av arbetsgivaravgift för 1000 gräns',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoAvArbAvg: {
				label:        'Avrundning arbetsgivaravgift',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoRbArbAvg: {
				label:        'Rabatt arbetsgivaravgift',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBruttolon: {
				label:        'Löner övriga',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonLedning: {
				label:        'Löner företagsledning/delägare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonTjmAvg: {
				label:        'Löner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonTjmSlf: {
				label:        'Löner tjänstemän (fri från avgift)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonTjmRed: {
				label:        'Löner tjänstemän (ungdomsavgifter)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsUtbSemTjm: {
				label:        'Utbetald semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonArbAvg: {
				label:        'Löner arbetare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonArbSlf: {
				label:        'Löner arbetare (fri från avgift)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonArbPen: {
				label:        'Löner arbetare (pensionärsavgifter)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonArbRed: {
				label:        'Löner arbetare (ungdomsavgifter)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsUtbSemArb: {
				label:        'Utbetald semesterlön arbetare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsSjkLonArb: {
				label:        'Sjuklön arbetare',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsSjkLonTjm: {
				label:        'Sjuklön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsSjkLonlon: {
				label:        'Sjuklön övriga',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsNettoErs: {
				label:        'Övriga kostnadsersättningar',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsNaturaFrm: {
				label:        'Förmånsvärden naturaförmån',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsUtbSemlon: {
				label:        'Utbetald semesterlön övriga',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerSemlon: {
				label:        'Beräknad semesterlön övriga',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrSemlon: {
				label:        'Avräknad semesterlön övriga',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KopplaBokf: {
				label:        'Till bokföring',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfProg: {
				label:        'Exportformat/program',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(4),
				],
			},

			BokfFtgId: {
				label:        'Företagsid',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => ['SE1P', 'SFSP'].includes(item.context.BokfProg) ? Validate.required(item) : true,
					Validate.maxLength(255),
				],
			},

			BokfVerSer: {
				label:        'Ver-serie i bokföringen',
				type:         String,
				defaultValue: 'F',
				rules:        [
					Validate.maxLength(1),
				],
			},

			BokfAntal: {
				label:        'Exportera antal',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh1: {
				label:        'Resultatenhet 1',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh2: {
				label:        'Resultatenhet 2',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh3: {
				label:        'Resultatenhet 3',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh4: {
				label:        'Resultatenhet 4',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh5: {
				label:        'Resultatenhet 5',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfResEnh6: {
				label:        'Resultatenhet 6',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfAnstNr: {
				label:        'Anställningsnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			BokfKopplaRE1: {
				label:        'Export av resultatenhet 1 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh1 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaRE2: {
				label:        'Export av resultatenhet 2 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh2 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaRE3: {
				label:        'Export av resultatenhet 3 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh3 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaRE4: {
				label:        'Export av resultatenhet 4 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh4 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaRE5: {
				label:        'Export av resultatenhet 5 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh5 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaRE6: {
				label:        'Export av resultatenhet 6 som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfResEnh6 ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			BokfKopplaAnst: {
				label:        'Export av anställningsnummer som',
				type:         String,
				defaultValue: '',
				rules:        [
					(item) => item.context.BokfAnstNr ? Validate.required({...item, label: 'Koppling'}) : true,
					Validate.maxLength(10),
				],
			},

			AmfTecknad: {
				label:        'Tecknad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AmfAvtalsnr: {
				label:        'Avtalsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(7),
				],
			},

			ForaUseHanforligLon: {
				label:        'Hantering av hänförlig lön',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			ForaUseFOKList: {
				label:        'Uppmärkning av anställda utförs i',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			DefaultDigitalMailbox: {
				label:        'Standardinställning för anställda',
				type:         String,
				defaultValue: 'NONE',
				rules:        [
					Validate.oneOf(['NONE', 'KIVRA', 'CLLS']),
				],
			},

			UseKivraDigitalMailbox: {
				label:        'Använd Kivra',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseCllsDigitalMailbox: {
				label:        'Använd Crona Lönebesked',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SafMedlem: {
				label:        'Medlem i Svenskt Näringsliv',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			SafArbplNr: {
				label:        'Arbetsplatsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(3),
				],
			},

			SafForbNr: {
				label:        'Förbundsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(2),
				],
			},

			SafAvtKod: {
				label:        'Avtalskod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(3),
				],
			},

			SafMedlNr: {
				label:        'Medlemsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(7),
				],
			},

			ITPTecknad: {
				label:        'Tecknad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			ITP1Prodid: {
				label:        'Avtalad plan (ITP 1)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},

			ITP2Prodid: {
				label:        'Avtalad plan (ITP 2)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},

			ITP2UseManBru: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [
				],
			},

			ITP2UseManRed: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [
				],
			},

			ITP2UseTimBru: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [
				],
			},

			ITP2UseTimRed: {
				label:        '',
				type:         Boolean,
				defaultValue: false,
				rules:        [
				],
			},

			ITP2ManBruKod: {
				label:        'Månadsavlönad',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			ITP2TimBruKod: {
				label:        'Timavlönad',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			ITP2ManRedKod: {
				label:        'Månadsavlönad',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			ITP2TimRedKod: {
				label:        'Timavlönad',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(100),
				],
			},

			VernrUseNext: {
				label:        'Nästa verifikationsnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			VernrNextNr: {
				label:        'Nästa verifikationsnummer',
				type:         Number,
				defaultValue: 1,
				rules:        [],
			},

			PrintoutPayslipBankkonto: {
				label:        'Bankkonto',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipSumTabSktGrund: {
				label:        'Tabellskattegrund',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipSumEngSktGrund: {
				label:        'Engångsskattegrund',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipTotArbGivAvg: {
				label:        'Arbetsgivaravgift',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipTotArbGivAvgPrc: {
				label:        'Arbetsgivaravgift %-sats',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipAckKompsaldo: {
				label:        'Komp',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipAckFlexsaldo: {
				label:        'Flex',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipAckEldDgrsaldo: {
				label:        'Extra ledighet',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipSemForsk: {
				label:        'Semester, förskott',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipSemSpaYear: {
				label:        'Semester, sparade dagar per år',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipLonArtNr: {
				label:        'Löneartsnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipLonRadGroupKopplad: {
				label:        'Visa kopplade rader efter varandra',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipLonRadSort: {
				label:        'Sortering av lönerader',
				type:         String,
				defaultValue: 'SortKod',
				rules:        [],
			},
			PrintoutPayslipLonRadSortType: {
				label:        'Typ av sortering',
				type:         String,
				defaultValue: 'alpha',
				rules:        [],
			},
			PrintoutPayslipSort: {
				label:        'Sortering vid utskrift av flera lönebesked',
				type:         String,
				defaultValue: 'AnstNr',
				rules:        [],
			},
			PrintoutPayslipLonPer: {
				label:        'Intjänandeperiod',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipRapPer: {
				label:        'Rapporteringsperiod',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipArbTid: {
				label:        'Arbetstid',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipJamkning: {
				label:        'Jämkning',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipOrgnr: {
				label:        'Organisationsnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			PrintoutPayslipBlankett: {
				label:        'Blankett',
				type:         String,
				defaultValue: 'standard',
				rules:        [],
			},
			PrintoutPayoutPayslipSort: {
				label:        'Sortering',
				type:         String,
				defaultValue: 'AnstNr',
				rules:        [],
			},
			EjFirstAnstLB: {
				label:        'Beräkna växa stöd först på AGI',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			PictureId: {
				label:        'Företagsbild',
				type:         String,
				defaultValue: '00000000-0000-0000-0000-000000000000',
				rules:        [],
			},

			KtoAvSlfAvg: {
				label:        'Avrundning löneskatt',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrPenAvg: {
				label:        'Kostnad avräknad pensionärsavgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrRedAvg: {
				label:        'Kostnad avräknad ungdomsavgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsAvrSlfAvg: {
				label:        'Avräknad löneskatt semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerPenAvg: {
				label:        'Kostnad beräknad pensionärsavgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerRedAvg: {
				label:        'Kostnad beräknad ungdomsavgift semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsBerSlfAvg: {
				label:        'Beräknad löneskatt semesterlön',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoKsLonTjmPen: {
				label:        'Löner tjänstemän (pensionärsavgifter)',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},


			KtoSkAvrSlfAvg: {
				label:        'Avräknad löneskatt pensionsavgifter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkBerSlfAvg: {
				label:        'Beräknad löneskatt pensionsavgifter',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KalArtSumma: {
				label:        'Sammanslagning ersättningar',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KalArtSummaTxt: {
				label:        'Sammanslagning med olika text',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KalArtSummaDat: {
				label:        'Sammanslagning med olika datum',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			ScbStatistik: {
				label:        'Statistik lämnas',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund1: {
				label:        'Använd grundande 1',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund2: {
				label:        'Använd grundande 2',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund3: {
				label:        'Använd grundande 3',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund4: {
				label:        'Använd grundande 4',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund5: {
				label:        'Använd grundande 5',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund6: {
				label:        'Använd grundande 6',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund7: {
				label:        'Använd grundande 7',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund8: {
				label:        'Använd grundande 8',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund9: {
				label:        'Använd grundande 9',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseGrund10: {
				label:        'Använd grundande 10',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			Grund1Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 1',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund2Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 2',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund3Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 3',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund4Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 4',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund5Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 5',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund6Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 6',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund7Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 7',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund8Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 8',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund9Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 9',
				rules:        [
					Validate.maxLength(15),
				],
			},

			Grund10Namn: {
				label:        'Namn',
				type:         String,
				defaultValue: 'Fri 10',
				rules:        [
					Validate.maxLength(15),
				],
			},

			BokfdatLon: {
				label:        'Bokför lönekostnad på',
				type:         String,
				defaultValue: 'U',
				rules:        [],
			},

			BokfdatSkatt: {
				label:        'Bokför skatterabatt på',
				type:         String,
				defaultValue: 'L',
				rules:        [],
			},

			UseKortNrPIN: {
				label:        'Använd PIN-kod för identifiering med kortnummer',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseLosen: {
				label:        'Underlag lämnas',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			LosenResEnh: {
				label:        'Resultatenhet',
				type:         Number,
				defaultValue: 0,
				rules:        [],
			},

			KPTecknad: {
				label:        'Tecknad',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KPArbGivNr: {
				label:        'Arbetsgivarnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(6),
				],
			},

			KPAvtalsNummer: {
				label:        'Avtalsnummer',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(9),
				],
			},

			KfoMedlem: {
				label:        'Medlem i Fremia',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KfoAvtKod: {
				label:        'Kollektivavtalskod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(6),
				],
			},

			KfoKomKod: {
				label:        'Kommunkod',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(4),
				],
			},

			LoneuppdragUtanOrdningsnummerOrdning: {
				label:        'Löneuppdrag som saknar ordningsnummer utförs',
				type:         String,
				defaultValue: 'BEFORE',
				rules:        [
					Validate.maxLength(6),
				],
			},

			OpenNewPerson: {
				label:        'Tillåt kopplingar att skapa nya anställda',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseNewAnstNr: {
				label:        'Endast anställningsnummer inom följande intervall är tillåtet',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			FromNewAnstNr: {
				label:        'Fr.o.m',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					(item) => item.context.UseNewAnstNr ? Validate.required(item) : true,
				],
			},

			TomNewAnstNr: {
				label:        'T.o.m',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					(item) => item.context.UseNewAnstNr ? Validate.required(item) : true,
				],
			},

			OpenNewBrukare: {
				label:        'Tillåt kopplingar att skapa nya brukare',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseNewBrukare: {
				label:        'Endast brukarnummer inom följande intervall är tillåtet',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			FromNewBrukare: {
				label:        'Fr.o.m',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					(item) => item.context.UseNewBrukare ? Validate.required(item) : true,
				],
			},

			TomNewBrukare: {
				label:        'T.o.m',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(15),
					(item) => item.context.UseNewBrukare ? Validate.required(item) : true,
				],
			},

			StdPayslipLocale: {
				label:        'Standardspråk lönebesked',
				type:         String,
				defaultValue: 'SV',
				rules:        [],
			},

			ArbPlatsTyp: {
				label:        'Normal arbetsplats',
				type:         String,
				defaultValue: 'FTP',
				rules:        [],
			},

			ArbPlatsAdr: {
				label:        'Arbetsplatsens gatuadress',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(50),
					(item) => item.context.ArbPlatsTyp === 'AVI' ? Validate.required(item) : true,
				],
			},

			ArbPlatsOrt: {
				label:        'Arbetsplatsens ort',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(50),
					(item) => item.context.ArbPlatsTyp === 'AVI' ? Validate.required(item) : true,
				],
			},

			KalAttestOblTid: {
				label:        'Kräv attest för tidregistreringar till lön',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			KalAttestOblErs: {
				label:        'Kräv attest för ersättningar till lön',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UseAttestWebLonHuv: {
				label:        'Använd attest för lönebesked',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			RequireAttestWebLonHuv: {
				label:        'Kräv att lönebesked attesteras innan de kan bearbetas vidare',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			AGILasPeriod: {
				label:        'Lås redovisningsperiod när AGI-fil skickas elektroniskt',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UsePayslipAckCard: {
				label:        'Visa kort för ackmulatorer i lönebeskedsvyn',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},

			UsePayslipAntCard: {
				label:        'Visa kort för anteckningar i lönebeskedsvyn',
				type:         Boolean,
				defaultValue: false,
				rules:        [],
			},
			
			KtoKsArbAvgLonTjm: {
				label:        'Arbetsgivaravgifter löner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
			},
			
			KtoKsArbAvgFrmTjm: {
				label:        'Arbetsgivaravgifter förmåner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsArbAvgSemTjm: {
				label:        'Arbetsgivaravgifter semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsRedAvgLonTjm: {
				label:        'Ungdomsavgifter löner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsRedAvgFrmTjm: {
				label:        'Ungdomsavgifter förmåner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsRedAvgSemTjm: {
				label:        'Ungdomsavgifter semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsPenAvgLonTjm: {
				label:        'Pensionärsavgifter löner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsPenAvgFrmTjm: {
				label:        'Pensionärsavgifter förmåner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsPenAvgSemTjm: {
				label:        'Pensionärsavgifter semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsSlfAvgLonTjm: {
				label:        'Löneskatt löner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsSlfAvgFrmTjm: {
				label:        'Löneskatt förmåner tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsSlfAvgSemTjm: {
				label:        'Löneskatt semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsBerArbAvgTjm: {
				label:        'Beräknad arbetsgivaravgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsAvrArbAvgTjm: {
				label:        'Avräknad arbetsgivaravgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsBerRedAvgTjm: {
				label:        'Kostnad beräknad ungdomsavgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsAvrRedAvgTjm: {
				label:        'Kostnad avräknad ungdomsavgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsBerPenAvgTjm: {
				label:        'Kostnad beräknad pensionärsavgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsAvrPenAvgTjm: {
				label:        'Kostnad avräknad pensionärsavgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsBerSlfAvgTjm: {
				label:        'Beräknad löneskatt semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},
			
			KtoKsAvrSlfAvgTjm: {
				label:        'Avräknad löneskatt semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkArbGivAvgTjm: {
				label:        'Arbetsgivaravgift tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkArbGivPenTjm: {
				label:        'Arbetsgivaravgift pensionär tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkArbGivRedTjm: {
				label:        'Arbetsgivaravgift ungdomar tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkSlfAvgiftTjm: {
				label:        'Särskild löneskatt tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkBerArbAvgTjm: {
				label:        'Beräknad arbetsgivaravgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			KtoSkAvrArbAvgTjm: {
				label:        'Avräknad arbetsgivaravgift semesterlön tjänstemän',
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(10),
				],
				helpTextId: 'bokforingsettings-konton',
			},

			FakArbstNr: {
				label:        'Arbetsställenr', //(fackförbund)
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(4),
				],
			},

			FakForbNr: {
				label:        'Förbundsnr', //(fackförbund)
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.maxLength(2),
				],
			},
		}
	}
}

export default FtgInfo;

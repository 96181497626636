/**
 * Created by simon on 2018-09-12.
 */

import Vue                                                                                                                    from 'vue';
import Vuex                                                                                                                   from 'vuex';
import moment                                                                                                                 from 'moment';
import IdleJs                                                                                                                 from 'idle-js'
import {noop, sortBy, keyBy, find, toNumber, isEmpty, first, keys, isPlainObject}                                             from 'lodash';
import AsyncTryCatch                                                                                                          from '../lib/AsyncTryCatch.js';
import UserNotificationBuilder                                                                                                from '../lib/UserNotificationBuilder.js';
import router                                                                                                                 from '../router/index.js';
import HttpRequester                                                                                                          from '../lib/HttpRequester.js';
import WebSocketClient                                                                                                        from '../lib/WebSocketClient.js';
import LocalStorage                                                                                                           from '../lib/LocalStorage.js';
import SessionStorage                                                                                                         from '../lib/SessionStorage.js';
import TabellYear                                                                                                             from '../models/TabellYear.js';
import PayslipsSharedFilterModel                                                                                              from '../models/PayslipsSharedFilterModel.js';
import {UserRights, PendingUserRights, LicUserRights, STDUserRights, STDReadOnlyUserRights, LonDemoUser, WebLonHuvAttestUser} from '../../shared/userRights.js';
import getSupplierInfo                                                                                                        from '../../shared/getSupplierInfo.js';

Vue.use(Vuex);

const getDefaultState = () => ({
	SupplierInfo:        getSupplierInfo.fromHostname(location.hostname),
	NetworkOffline:      false,
	LicenseSupplier:     null,
	IdleTracker:         null,
	UserAccount:         {},
	UserRights:          new PendingUserRights(),
	HasLicensOption:     {},
	HasUserOption:       {},
	FtgInfo:             {},
	Authenticated:       false,
	Notifications:       [],
	Traktamente:         [],
	TidKoder:            [],
	TidKodGrupp:         [],
	LonKoder:            [],
	LonKodGrupp:         [],
	Varugrupper:         [],
	FriaAckarDef:        [],
	Egenskaper:          [],
	KontoReg:            [],
	BrukarLista:         [],
	BrukarAnsvLista:     [],
	LSSKostnTypLista:    [],
	LSSInfo:             [],
	LastLSSPeriod:       null,
	PerLista:            [],
	PeriodLista:         [],
	AvdelningLista:      [],
	ForaFOKList:         [],
	UnreadMessagesCount: 0,
	OBRegler:            [],
	JorRegler:           [],
	BerRegler:           [],
	EldRegler:           [],
	OvtRegler:           [],
	ArtRegLista:         {}, //TabellKod: Array
	TabellNamnLista:     [],
	TabellYearSettings:  {},
	ArbSchemaList:       [],
	ResultatEnheter:     {
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
	},
	KonHuvLista:            [],
	AgreementsList:         [],
	VacationAgreementsList: [],
	Yrkesregister:          [],
	FackFor:                [],
	Holidays:               {}, //Indexed by date
	FormelVariables:        [],
	SalaryYears:            [],
	ImpReg:                 [],
	PortalInfo:             null,
	HasPlus:                false,
	SalaryYear:             null,
	Snackbar:               {
		title:     '',
		text:      '',
		color:     'error',
		visible:   false,
		timeout:   10000,
		multiline: false,
	},
	ConfirmDialog: {
		visible:             false,
		title:               '',
		text:                '',
		cancelText:          'Nej',
		confirmText:         'Ja',
		altConfirmText:      '',
		onConfirm:           noop,
		onCancel:            noop,
		onAltConfirm:        noop,
		showCancel:          true,
		showConfirm:         true,
		showAltConfirm:      false,
		autoFocusConfirm:    false,
		autoFocusAltConfirm: false,
	},
	WebSocketClient:   null,
	SharedViewFilters: {
		selectedEmployee:            null,
		selectedSalaryPeriod:        null,
		selectedTabellKod:           null,
		selectedTabellStyKod:        null,
		selectedCalendarDisplayMode: 'DATE',
		payslipSelectedEmployee:     null,
		salaryPeriodsByAnstNr:       {},
		showAutoCalc:                LocalStorage.get('ShowAutoCalc', true),
		showTransferredToSalary:     LocalStorage.get('ShowTransferredToSalary', true),
		payslipsSharedFilterModel:   new PayslipsSharedFilterModel(),
		dateFilter:                  {
			Locked: false,
			From:   moment().startOf('month').format('YYYY-MM-DD'),
			To:     moment().endOf('month').format('YYYY-MM-DD'),
		},
	},
});

const setSnackbar = (context, {title = '', text = '', color = 'error', multiline = false, timeout = 10000, position = 'bottom', right = false, left = false} = {}, dispatch) => {
	const hasVisibleSnackbar = context.state.Snackbar.visible;

	dispatch('clearSnackbar');

	if(text) {
		if(timeout <= 10000 && text.length > 100) {
			timeout = 20000;
		}

		const newSnackbarData = {visible: true, title, text, color, multiline, timeout, position, right, left};

		if(hasVisibleSnackbar) {
			//Allow existing snackbar to disappear before showing next snackbar
			setTimeout(() => {
				context.state.Snackbar = newSnackbarData;
			}, 1);
		} else {
			context.state.Snackbar = newSnackbarData;
		}
	}
};

export default new Vuex.Store({
	state: getDefaultState(),

	actions: {
		async refreshSalaryYears(context) {
			const salaryYears = await HttpRequester.get('/salaryyears');

			context.state.SalaryYears = salaryYears;

			if(context.state.SalaryYear && !context.state.SalaryYears.find((value) => value === context.state.SalaryYear)) {
				this.dispatch('setSalaryYear', {Year: salaryYears[0]});
			}
		},

		setAuthenticated(context, {
			UserAccount,
			FtgInfo = null,
			SalaryYears = null,
			SessionSalaryYear = null,
			HasPlus = null,
			HasLicensOption,
			Supplier = null,
		}) {
			context.state.UserAccount = UserAccount;

			if(HasLicensOption) {
				context.state.HasLicensOption = HasLicensOption;
			}

			const {UserOption = []} = UserAccount;

			context.state.HasUserOption = {};
			for(const item of UserOption) {
				context.state.HasUserOption[item.Option] = true;
			}

			if(HasPlus === false || HasPlus === true) {
				context.state.HasPlus = HasPlus;
			}

			if(Supplier) {
				context.state.LicenseSupplier = Supplier;

				if(process.env.VUE_APP_REDIRECT_TO_SUPPLIER_IF_NEEDED === 'true' && Supplier !== context.state.SupplierInfo.Supplier) {
					const realSupplierInfo = getSupplierInfo.fromSupplier(Supplier);

					if(realSupplierInfo && realSupplierInfo.Supplier !== context.state.SupplierInfo.Supplier && realSupplierInfo.Hostname) {
						window.location = `https://${realSupplierInfo.Hostname}/`
					}
				}
			}

			clearContextCache(context, {
				clearSelectedEmployee: FtgInfo && context.state.FtgInfo && context.state.FtgInfo.FtgId && context.state.FtgInfo.FtgId !== FtgInfo.FtgId,
			});

			if(FtgInfo) {
				context.state.FtgInfo = FtgInfo;
				SessionStorage.set('FtgId', FtgInfo.FtgId);

				if(FtgInfo.FtgId) {
					this.dispatch('loadPortalInfo');
					this.dispatch('loadUserRightsGroup');
					this.dispatch('loadAgreementsList');
				}
			}

			if(SalaryYears && SalaryYears.length > 0) {
				context.state.SalaryYears = SalaryYears;

				if(SessionSalaryYear) {
					context.state.SalaryYear = SessionSalaryYear;
					SessionStorage.set('SalaryYear', SessionSalaryYear);
				} else {
					this.dispatch('setSalaryYear', {Year: first(context.state.SalaryYears)});
				}
			}

			context.state.Authenticated = true;

			this.dispatch('setupWebSocketClient');
			this.dispatch('loadNotifications');
			this.dispatch('createIdleTimer');

			Vue.prototype.$eventBus.$emit('setNavigationItems');
		},

		createIdleTimer(context) {
			if(context.state.IdleTracker) {
				context.state.IdleTracker.stop();
				context.state.IdleTracker = null;
			}

			const idleTimer = LocalStorage.get('InactivityTimer', 60) * 60000;

			if(idleTimer > 0) {
				context.state.IdleTracker = new IdleJs({
					idle:        idleTimer,
					events:      ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
					startAtIdle: false,
					onIdle() {
						context.state.IdleTracker.stop();
						context.state.IdleTracker = null;
						context.dispatch('logout');

						try {
							const {$children = []} = router.app;
							const [appView] = $children;

							appView.showInactivityDialog();
						} catch{}
					},
				});

				context.state.IdleTracker.start();
			}
		},

		addSalaryYear(context, Year) {
			context.state.SalaryYears = [String(Year), ...context.state.SalaryYears];
		},

		setUpdatedFtgInfo(context, FtgInfo) {
			context.state.FtgInfo = FtgInfo;
			Vue.prototype.$eventBus.$emit('setNavigationItems');

			this.dispatch('clearFormelVariablesCache');
		},

		clearFormelVariablesCache(context) {
			//Clear FormelVariables cache
			if(context.state.FormelVariables) {
				context.state.FormelVariables = {};
			}
		},

		setSalaryYear(context, {Year}) {
			Year = toNumber(Year);

			if(Year === context.state.SalaryYear) {
				return;
			}

			clearContextCache(context);

			//This automatically triggers a re-render of page as it is keyed by salaryyear (router-view)
			context.state.SalaryYear = Year;
			SessionStorage.set('SalaryYear', Year);
		},

		logout(context, {saveSnackbarState = false} = {}) {
			if(context.state.IdleTracker) {
				context.state.IdleTracker.stop();
			}

			if(context.state.WebSocketClient) {
				context.state.WebSocketClient.close();
			}

			SessionStorage.clear();

			const defaultState = getDefaultState();

			//Preserve snackbar if any are visible, such as network error
			if(saveSnackbarState) {
				delete defaultState.Snackbar;
			}

			Object.assign(context.state, defaultState);
		},

		addNotification(context, data) {
			const {skipSnackbar = false} = data;

			data = UserNotificationBuilder(data)

			if(!data || !data.Message) {
				return;
			}

			if(data.Id && context.state.Notifications.find(({Id}) => Id === data.Id)) {
				return;
			}

			context.state.Notifications.push(data);
			context.state.Notifications = sortBy(context.state.Notifications, 'TimeStamp').reverse();

			if(skipSnackbar) {
				return;
			}

			if(!data.FtgId || (data.FtgId === context.state.FtgInfo.FtgId)) {
				this.dispatch('setSnackbar', {
					title: data.Title,
					text:  data.Message,
					color: data.Icon.Color,
				});
			}
		},

		clearNotifications(context) {
			context.state.Notifications = [];
		},

		toggleNetworkOffline(context, toggle) {
			context.state.NetworkOffline = toggle;
		},

		setSnackbar(context, options = {}) {
			setSnackbar(context, options, this.dispatch);
		},

		clearSnackbar(context) {
			context.state.Snackbar.visible = false;
		},

		//TODO: -- Wrap async methods in try-catch with corresponding error handler

		async loadTidKoder(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.TidKoder, refreshCache)) {
				context.state.TidKoder = await HttpRequester.get('/tidkoder');
				context.state.TidKoder.lastFetchedTime = new Date();
			}
		},

		async loadTidKodGrupp(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.TidKodGrupp, refreshCache)) {
				context.state.TidKodGrupp = await HttpRequester.get('/tidkoder/groups');
				context.state.TidKodGrupp.lastFetchedTime = new Date();
			}
		},

		async loadLonKoder(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.LonKoder, refreshCache)) {
				context.state.LonKoder = await HttpRequester.get('/lonkoder');
				context.state.LonKoder.lastFetchedTime = new Date();
			}
		},

		async loadLonKodGrupp(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.LonKodGrupp, refreshCache)) {
				context.state.LonKodGrupp = await HttpRequester.get('/lonkoder/groups');
				context.state.LonKodGrupp.lastFetchedTime = new Date();
			}
		},

		async loadYrkesregister(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Yrkesregister, refreshCache)) {
				context.state.Yrkesregister = await HttpRequester.get('/yrkesregister', {
					params: {
						fields: ['NYKKod', 'Titel'],
					},
				});
				context.state.Yrkesregister.lastFetchedTime = new Date();
			}
		},

		async loadFackFor(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.FackFor, refreshCache)) {
				context.state.FackFor = context.state.HasPlus ? await HttpRequester.get('/fackfor') : [];
				context.state.FackFor.lastFetchedTime = new Date();
			}
		},

		async loadVarugrupper(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Varugrupper, refreshCache)) {
				context.state.Varugrupper = await HttpRequester.get('/varugrupper');
				context.state.Varugrupper.lastFetchedTime = new Date();
			}
		},

		async loadTraktamente(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Traktamente, refreshCache)) {
				context.state.Traktamente = await HttpRequester.get('/traktamente', {
					fields: [
						'Landskod',
						'Land',
					],
				});
				context.state.Traktamente.lastFetchedTime = new Date();
			}
		},

		async loadImpReg(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.ImpReg, refreshCache)) {
				context.state.ImpReg = await HttpRequester.get('/impreg', {
					params: {
						fields: ['Id', 'BenText', 'Format', 'ImpPerReg', 'ImpBrukarReg'],
					},
				});
				context.state.ImpReg.lastFetchedTime = new Date();
			}
		},

		async loadEgenskaper(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Egenskaper, refreshCache)) {
				context.state.Egenskaper = context.state.HasPlus ? await HttpRequester.get('/egenskaper') : [];
				context.state.Egenskaper.lastFetchedTime = new Date();
			}
		},

		async loadFriaAckarDef(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.FriaAckarDef, refreshCache)) {
				context.state.FriaAckarDef = await HttpRequester.get('/FriaAckarDef');
				context.state.FriaAckarDef.lastFetchedTime = new Date();
			}
		},


		async loadKontoReg(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.KontoReg, refreshCache)) {
				context.state.KontoReg = await HttpRequester.get('/kontoreg');
				context.state.KontoReg.lastFetchedTime = new Date();
			}
		},

		async loadLSSKostnTypLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.LSSKostnTypLista, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.LSSKostnTypLista = [];
					
					return;
				}
				context.state.LSSKostnTypLista = await HttpRequester.get('/lssKostnTyp');
				context.state.LSSKostnTypLista.lastFetchedTime = new Date();
			}
		},

		async loadLastLSSPeriod(context, {refreshCache = false} = {}) {
			if(!context.state.LastLSSPeriod || refreshCache === true) {
				const [{LSSPeriod = null} = {}]            = await HttpRequester.get('/lssberedning/periodOverview', {
					params: {
						sortBy:    'LSSPeriod',
						sortOrder: 'desc',
						pageNum:   1,
						perPage:   1,
						fields:    ['LSSPeriod'],
					},
				});
				context.state.LastLSSPeriod = LSSPeriod;
			}
		},

		async loadLSSInfo(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.LSSInfo, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.LSSInfo = [];
					
					return;
				}

				context.state.LSSInfo = sortBy(await HttpRequester.get('/lssInfo'), 'Datum').reverse();
			}
		},

		async loadBrukarLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BrukarLista, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.BrukarLista = [];
					
					return;
				}
				context.state.BrukarLista = await HttpRequester.get('/brukarlista');
				context.state.BrukarLista.lastFetchedTime = new Date();
			}
		},

		async loadBrukarAnsvLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BrukarAnsvLista, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.BrukarAnsvLista = [];
					
					return;
				}
				context.state.BrukarAnsvLista = await HttpRequester.get('/brukaransvlista');
				context.state.BrukarAnsvLista.lastFetchedTime = new Date();
			}
		},

		async loadPeriodLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.PeriodLista, refreshCache)) {
				context.state.PeriodLista = await HttpRequester.get('/salaryPeriods');
				context.state.PeriodLista.lastFetchedTime = new Date();
			}
		},

		async loadPerLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.PerLista, refreshCache)) {
				context.state.PerLista = await HttpRequester.get('/perlista');
				context.state.PerLista.lastFetchedTime = new Date();

				if(!context.state.SharedViewFilters.selectedEmployee && context.state.PerLista.length > 0) {
					context.state.SharedViewFilters.selectedEmployee = context.state.PerLista[0];
				} else if(context.state.PerLista.length === 0 && router.currentRoute && router.currentRoute.meta.RequireAtleastOneEmployeeInRegistry) {
					context.state.PerLista.lastFetchedTime = null;

					setSnackbar(context, {
						title:   'Tomt personalregister',
						text:    'Företagets personalregister är tomt. För att komma åt funktionen måste du lägga upp anställda först. Du har omredigerats till personalregistret.',
						timeout: 15000,
					}, this.dispatch);

					router.push({
						name: 'Personalregister',
					});
				}
			}
		},

		async loadForaFOKList(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.ForaFOKList, refreshCache)) {
				context.state.ForaFOKList = await HttpRequester.get('/foraFOKList');
				context.state.ForaFOKList.lastFetchedTime = new Date();
			}
		},

		async loadAvdelningLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.AvdelningLista, refreshCache)) {
				context.state.AvdelningLista = await HttpRequester.get('/avdelning');
				context.state.AvdelningLista.lastFetchedTime = new Date();
			}
		},

		async loadOBRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.OBRegler, refreshCache)) {
				context.state.OBRegler = context.state.HasPlus ? await HttpRequester.get('/obregler', {
					params: {
						fields: ['OBRegelID', 'BenText'],
					},
				}) : [];
				context.state.OBRegler.lastFetchedTime = new Date();
			}
		},

		async loadJorRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.JorRegler, refreshCache)) {
				context.state.JorRegler = context.state.HasPlus ? await HttpRequester.get('/jorregler', {
					params: {
						fields: ['JorRegelID', 'BenText'],
					},
				}) : [];
				context.state.JorRegler.lastFetchedTime = new Date();
			}
		},

		async loadBerRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BerRegler, refreshCache)) {
				context.state.BerRegler = context.state.HasPlus ? await HttpRequester.get('/berregler', {
					params: {
						fields: ['BerRegelID', 'BenText'],
					},
				}) : [];
				context.state.BerRegler.lastFetchedTime = new Date();
			}
		},

		async loadEldRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.EldRegler, refreshCache)) {
				context.state.EldRegler = context.state.HasPlus ? await HttpRequester.get('/eldregler', {
					params: {
						fields: ['EldRegelID', 'BenText'],
					},
				}) : [];
				context.state.EldRegler.lastFetchedTime = new Date();
			}
		},

		async loadOvtRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.OvtRegler, refreshCache)) {
				context.state.OvtRegler = context.state.HasPlus ? await HttpRequester.get('/ovtregler', {
					params: {
						fields: ['OvtRegelID', 'BenText'],
					},
				}) : [];
				context.state.OvtRegler.lastFetchedTime = new Date();
			}
		},

		async loadResEnheter(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.ResultatEnheter, refreshCache)) {
				if(
					context.state.FtgInfo &&
					!context.state.FtgInfo.OpenResEnhet1 &&
					!context.state.FtgInfo.OpenResEnhet2 &&
					!context.state.FtgInfo.OpenResEnhet3 &&
					!context.state.FtgInfo.OpenResEnhet4 &&
					!context.state.FtgInfo.OpenResEnhet5 &&
					!context.state.FtgInfo.OpenResEnhet6
				) {
					context.state.ResultatEnheter[1] = [];
					context.state.ResultatEnheter[2] = [];
					context.state.ResultatEnheter[3] = [];
					context.state.ResultatEnheter[4] = [];
					context.state.ResultatEnheter[5] = [];
					context.state.ResultatEnheter[6] = [];
					
					return;
				}

				const {ResEnh1, ResEnh2, ResEnh3, ResEnh4, ResEnh5, ResEnh6} = await HttpRequester.get('/resenheter');
				context.state.ResultatEnheter[1] = ResEnh1;
				context.state.ResultatEnheter[2] = ResEnh2;
				context.state.ResultatEnheter[3] = ResEnh3;
				context.state.ResultatEnheter[4] = ResEnh4;
				context.state.ResultatEnheter[5] = ResEnh5;
				context.state.ResultatEnheter[6] = ResEnh6;
				context.state.ResultatEnheter.lastFetchedTime = new Date();
			}
		},

		async loadArbSchemaList(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.ArbSchemaList, refreshCache)) {
				context.state.ArbSchemaList = await HttpRequester.get('/arbschemalist');
				context.state.ArbSchemaList.lastFetchedTime = new Date();
			}
		},

		async loadArtRegLista(context, {TabellKod, refreshCache = false} = {}) {
			if(!context.state.ArtRegLista[TabellKod]) {
				context.state.ArtRegLista[TabellKod] = [];
			}
			if(shouldRefreshCachedList(context.state.ArtRegLista[TabellKod], refreshCache)) {
				context.state.ArtRegLista[TabellKod] = await HttpRequester.get(`/artreg/${encodeURIComponent(TabellKod)}`, {
					params: {
						fields: [
							'LonArtNr',
							'BenText',
							'KlassTyp',
							'AntalsKod',
							'BeloppsKod',
							'SummaKod',
							'Aktiv',
							'LockStandard',
							'AntalEnhet',
							'UpdKompsaldo',
							'UpdFlexsaldo',
							'SemGruFranv',
							'SemFranTyp',
						],
					},
				});
				context.state.ArtRegLista[TabellKod].lastFetchedTime = new Date();
			}
		},

		async loadTabellNamnLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.TabellNamnLista, refreshCache)) {
				context.state.TabellNamnLista = await HttpRequester.get('/tabellnamn');
				context.state.TabellNamnLista.lastFetchedTime = new Date();
			}
		},

		async setSharedViewFiltersTabellKodFromEmployee(context, employee) {
			const selectedEmployee = employee || context.state.SharedViewFilters.selectedEmployee || context.state.SharedViewFilters.payslipSelectedEmployee;

			if(!selectedEmployee) {
				return;
			}

			try {
				await this._actions.loadAgreementsList[0](context);

				const {TabellKod} = context.state.AgreementsList.find((item) => item.AvtalId === selectedEmployee.AvtalId);

				context.state.SharedViewFilters.selectedTabellKod = TabellKod;
				context.state.SharedViewFilters.selectedTabellStyKod = selectedEmployee.TabellStyKod;
			} catch{}
		},

		async loadKonHuvLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.KonHuvLista, refreshCache)) {
				context.state.KonHuvLista = await HttpRequester.get('/konhuvlista');
				context.state.KonHuvLista.lastFetchedTime = new Date();
			}
		},

		async getTabellYearSettings(context, {TabellKod} = {}) {
			if(!context.state.TabellYearSettings[TabellKod]) {
				context.state.TabellYearSettings[TabellKod] = new TabellYear(await HttpRequester.get(`/tabellyear/${encodeURIComponent(TabellKod)}`));
			}

			return context.state.TabellYearSettings[TabellKod];
		},

		clearTabellYearSettings(context, {TabellKod} = {}) {
			context.state.TabellYearSettings[TabellKod] = null;
		},

		async loadAgreementsList(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.AgreementsList, refreshCache)) {
				context.state.AgreementsList = await HttpRequester.get('/agreements', {
					params: {
						fields: [
							'AvtalId',
							'Avdelning',
							'BenText',
							'CanDelete',
							'TabellKod',
							'AmfTyp',
							'ForaReportType',
							'ForaMarkups',
							'Pen1Tecknad',
							'Pen1Namn',
							'Pen1Prc',
							'Pen2Tecknad',
							'Pen2Namn',
							'Pen2Prc',
							'ITPPlan',
							'SemAvtalKod',
							'UtbetPer',
							'KPTyp',
							'KPUseArbGivNr',
							'KPArbGivNr',
							'KPUseAvtalsNr',
							'KPAvtalsNummer',
							'UseScbArbstKU',
							'ScbArbstKU',
							'UseWarningsForActiveEmployments',
							'OpenOBRegler',
							'OpenJORRegler',
							'OpenBERRegler',
							'OpenELDRegler',
							'OpenOVTRegler',
							'FerIngBer',
							'FerUphLon',
							'UseFakArbstNr',
							'FakArbstNr',
							'UseFakForbNr',
							'FakForbNr',
						],
					},
				});
				context.state.AgreementsList.lastFetchedTime = new Date();
				Vue.prototype.$eventBus.$emit('setNavigationItems');
			}
		},

		async loadVacationAgreementsList(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.VacationAgreementsList, refreshCache)) {
				context.state.VacationAgreementsList = await HttpRequester.get('/semavtallist', {
					params: {
						fields: ['AvtalKod', 'BenText', 'StartSemYear', 'StartIntjanande', 'Sammanfallande', 'SemDagarTyp', 'SemDagar', 'UseSemTil', 'UseSemRor', 'EndastLon', 'AgeGroups'],
					},
				});
				context.state.VacationAgreementsList.lastFetchedTime = new Date();
			}
		},

		async loadHolidays(context, {refreshCache = false} = {}) {
			if(isEmpty(context.state.Holidays) || refreshCache === true) {
				context.state.Holidays = keyBy(await HttpRequester.get(`/holidays/${context.state.SalaryYear}`), 'Datum')
			}
		},

		async loadFormelVariables(context, {refreshCache = false} = {}) {
			if(isEmpty(context.state.FormelVariables) || refreshCache === true) {
				context.state.FormelVariables                 = await HttpRequester.get('/formel/variables');
				context.state.FormelVariables.lastFetchedTime = new Date();
			}
		},

		confirmDialog(context, options = {}) {
			options.showAltConfirm      = options.showAltConfirm === true;
			options.persistent          = options.persistent === true;
			options.autoFocusConfirm    = options.autoFocusConfirm === true;
			context.state.ConfirmDialog = {...options, visible: true};
		},

		hideConfirmDialog(context) {
			context.state.ConfirmDialog.visible = false;
		},

		setupWebSocketClient(context) {
			if(!context.state.WebSocketClient) {
				context.state.WebSocketClient = new WebSocketClient();
			}
		},

		loadNotifications() {
			AsyncTryCatch(async () => {
				const Notifications = await HttpRequester.get('/notifications');

				for(const item of Notifications) {
					this.dispatch('addNotification', {
						...item,
						skipSnackbar: true,
					});
				}
			});
		},

		setUserRightsGroup(context, userRightsGroup) {
			context.state.UserRights = userRightsGroup;
		},

		async loadUserRightsGroup(context, callback = noop) {
			if(context.state.UserAccount.Roll === 'LIC') {
				context.state.UserRights = new LicUserRights();
			} else {
				try {
					if(context.state.UserAccount.LicNr === process.env.VUE_APP_LONDEMO_LICNR) {
						context.state.UserRights = new LonDemoUser();

						return callback({isSTD: true});
					}

					const userRight = await HttpRequester.get('/userRightsGroups/user/current');

					switch (userRight) {
						case 'STDUSER': {
							context.state.UserRights = new STDUserRights();

							return callback({isSTD: true});
						}
						case 'STDREAD': {
							context.state.UserRights = new STDReadOnlyUserRights();

							return callback({isSTD: true});
						}
						case 'WEBLONHUVATTESTUSER': {
							context.state.UserRights = new WebLonHuvAttestUser();

							return callback({isSTD: true});
						}
					}

					const userRights = await HttpRequester.get(`/userRightsGroups/${encodeURIComponent(userRight)}`);

					if(userRights) {
						context.state.UserRights = new UserRights(userRights);

						return callback({isSTD: false});
					}
				} catch{} finally {
					Vue.prototype.$eventBus.$emit('setNavigationItems');
					router.checkRight(router.currentRoute, '/infopanel');
				}
			}
		},

		async loadPortalInfo(context) {
			context.state.PortalInfo = await HttpRequester.get('/portalInfo', {
				params: {
					fields: [
						'EnableDVSupport',
						'LoginType',
						'KlockSenAvrFruk',
						'KlockSenAvrLnch',
						'KlockSenAvrMidd',
						'KlockTidHemFruk',
						'KlockTidHemLnch',
						'KlockTidHemMidd',
						'LogiFrukost',
						'LogiLunch',
						'LogiMiddag',
						'LogiNatt',
					],
				},
			});

			this.dispatch('loadMessagesStatus');
		},

		async loadMessagesStatus(context) {
			const {AntalOlast} = await HttpRequester.get('/messages/status');

			context.state.UnreadMessagesCount = AntalOlast;
		},

		addUnreadMessagesCount(context, Amount) {
			context.state.UnreadMessagesCount += Amount;
		},

		updatePerListaProfilePictureId(context, {AnstNr, ProfilePictureId}) {
			const item = context.state.PerLista.find((item) => item.AnstNr === AnstNr);

			if(item) {
				item.ProfilePictureId = ProfilePictureId;
			}
		},

		updateBrukarListaProfilePictureId(context, {Brukare, ProfilePictureId}) {
			const item = (context.state.BrukarLista || []).find((item) => item.Brukare === Brukare);

			if(item) {
				item.ProfilePictureId = ProfilePictureId;
			}
		},
	},
});

const cacheTime = 15; //Minutes
function shouldRefreshCachedList(list, refreshCache = false) {
	return refreshCache === true ||
		list.length === 0 ||
		!list.lastFetchedTime ||
		((Date.now() - list.lastFetchedTime) / 1000 / 60) > cacheTime
}

function clearContextCache(context, {clearSelectedEmployee = false} = {}) {
	const storeKeys = keys(context.state);

	for(const key of storeKeys) {
		if(isPlainObject(context.state[key]) && find(context.state[key], (value) => value && value.lastFetchedTime)) {
			context.state[key] = {};
		} else if(context.state[key] && context.state[key].lastFetchedTime) {
			context.state[key].lastFetchedTime = null;
		}
	}

	context.state.TabellYearSettings = {};
	context.state.SharedViewFilters.selectedSalaryPeriod      = null;
	context.state.SharedViewFilters.salaryPeriodsByAnstNr     = {};
	context.state.LastLSSPeriod                               = null;
	context.state.SharedViewFilters.payslipsSharedFilterModel = new PayslipsSharedFilterModel();

	if(clearSelectedEmployee) {
		context.state.SharedViewFilters.selectedEmployee = null;
	}
}

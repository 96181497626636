/**
 * Created by simon on 2018-12-27.
 */


import _                 from 'lodash';
import Vue               from 'vue';
import {mapState}        from 'vuex';
import store             from '../store/index.js';
import HttpRequester     from '../lib/HttpRequester.js';
import Formatter         from '../lib/Formatter.js';
import {LonDemoUser}     from '../../shared/userRights.js';
import sharedViewFilters from './sharedViewFilters.js';
import downloadFile      from './thirdparty/download.js';

Vue.prototype.$eventBus  = new Vue();
Vue.prototype.$http      = HttpRequester;
Vue.prototype.$formatter = Formatter;

const DV = {
	install(Vue) {
		Vue.mixin({
			methods: {
				$validateForm({ref = 'form', showMsgError = true} = {}) {
					const form = this.$refs[ref];

					if(!form) {
						console.warn(`Form with reference ${ref} does not exist.`)
						
						return false;
					}


					if(!form.validate()) {
						const {inputs = []}     = form;
						const firstInvalidInput = inputs.find((item) => !item.valid);

						this.$changeTabsToShowComponent(firstInvalidInput);

						if(showMsgError) {
							for(const input of inputs) {
								if(input.validations.length > 0) {
									this.$msgError(input.validations[0]);
									input.focus();
									break;
								}
							}
						}

						return false;
					}

					return true;
				},

				$downloadFile(fileContent, fileName) {
					downloadFile(fileContent, fileName);
				},

				$buildBrandedAssetsURL(url = '') {
					if(url[0] === '/') {
						url = url.slice(1);
					}

					return `${process.env.VUE_APP_BRANDED_ASSETS_PATH}${url}`
				},

				$buildURL(url, {apiRoute = false, skipBaseURI = false} = {}) {
					//Redirect to API server when running in UTV mode (since it's different ports)
					const {VUE_APP_BASE_URI, VUE_APP_API_URI} = process.env;

					if(skipBaseURI === false && url.slice(0, VUE_APP_BASE_URI.length) !== VUE_APP_BASE_URI) {
						if(url.slice(0, 1) === '/') {
							url = url.slice(1);
						}
						url = `${VUE_APP_BASE_URI}/${url}`;
					}

					if(apiRoute) {
						const isPrint        = url.startsWith('/print');
						const isFileDownload = url.toLowerCase().startsWith('/filedownload');

						url = `${VUE_APP_API_URI}${url}`;

						if(isPrint || isFileDownload) {
							url = url.replace('/api/', '/');
						}
					}

					return url;
				},

				$openNewTab(url, {target = '_blank', apiRoute = false, skipBaseURI = false} = {}) {
					window.open(this.$buildURL(url, {apiRoute, skipBaseURI}), target);
				},

				$msgSuccess(message) {
					this.$msg({text: message, color: 'success'});
				},

				$msgInfo(message) {
					this.$msg({text: message, color: 'info'});
				},

				$msgError(message) {
					this.$msg({text: message, color: 'error'});
				},

				$msgWarn(message) {
					this.$msg({text: message, color: 'accent'});
				},

				$msg(options = {}) {
					store.dispatch('setSnackbar', options);
				},

				onBlurPersNr(model = this.model, fieldName = 'PersNr') {
					model[fieldName] = this.$formatter.persNr(model[fieldName]);
				},

				onFocusPersNr(model = this.model, fieldName = 'PersNr') {
					model[fieldName] = model[fieldName].replace(/\D+/g, '');
				},

				$frontendLog(error, additionalContext = {}) {
					if(error instanceof HttpRequester.BackendError) {
						return;
					}

					this.$tryCatch({
						task: async () => {
							const {fullPath: url, params}                     = this.$route || {};
							const {response = null, code = null, config = {}} = error;
							const {method, url: reqURL, data: requestData, headers, params: reqParams}      = config;
							const {stack, message} = error;

							await this.$http.post('/frontendlogger', {
								message,
								stack,
								url,
								context: {
									url,
									params,
									code,
									httpReq: reqURL ? {
										method,
										url:    reqURL,
										requestData,
										headers,
										params: reqParams,
									} : undefined,
									httpRes: response ? {
										data:    response ? response.data : null,
										status:  response ? response.status : null,
										headers: response ? response.headers : null,
									} : undefined,
									additionalContext,
								},
							}, {
								skipBackendMessageSnackbar: true,
							});
						},
					});
				},

				$frontendTracker(id, additionalContext) {
					if(!id) {
						return;
					}

					const {fullPath: url, params} = this.$route || {};

					this.$tryCatch({
						task: async () => {
							await this.$http.post('/frontendtracker', {
								id,
								url,
								context: {
									url,
									params,
									id,
									additionalContext,
								},
							}, {
								skipBackendMessageSnackbar: true,
							});
						},
					});
				},

				//Change tabs over to first invalid field
				$changeTabsToShowComponent(component) {
					if(component) {
						let target = component.$parent;
						let horizontalTab = null;
						let horizontalTabIndex = null;
						let previous = [];
						let ToggleCard = null;

						while(target.$parent) {
							if(target.setActiveTab && target.$options && ['HorizontalTabLayout'].includes(target.$options._componentTag)) {
								horizontalTab = target;
								const horizontalTabItemsComponent = _.find(target.$children, (item) => item._uid === _.last(previous)._uid);
								if(horizontalTabItemsComponent) {
									const uidStack = new Set(_.map(previous, '_uid'));
									let localTarget = horizontalTabItemsComponent;

									try {
										if(horizontalTabItemsComponent.$children[0].$el.className.includes('v-form')) {
											localTarget = horizontalTabItemsComponent.$children[0];
										}
									} catch{}

									horizontalTabIndex = _.findIndex(localTarget.$children, (item) => uidStack.has(item._uid));
								}
								previous = [];
							} else if(target.$options && target.$options._componentTag === 'ToggleCard') {
								ToggleCard = target;
							}

							//If in dialog, then don't try to swap tabs etc
							if(target && target.$options && target.$options._componentTag === 'Dialog') {
								break;
							}

							previous.push(target);
							target = target.$parent;
						}

						if(horizontalTab) {
							horizontalTab.setActiveTab(horizontalTabIndex);
						}

						if(ToggleCard) {
							ToggleCard.open();
						}
					}

					setTimeout(() => {
						if(component.$el || component.$refs.input) {
							this.$vuetify.goTo(component.$el || component.$refs.input, {offset: 100});
						}
					}, 50);
				},

				$registerEventSourceHandler(eventName, handler = () => ({})) {
					this.$eventSourceHandlers = this.$eventSourceHandlers || {};
					this.$eventSourceHandlers[eventName] = handler.bind(this);
					this.$eventBus.$on(`WebSocketClient:${eventName}`, this.$eventSourceHandlers[eventName], this);
				},

				$isBirthday(PersNr) {
					const now = new Date();

					return PersNr && PersNr.length === 12 && (PersNr || '').slice(4, 8) === `${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}`;
				},

				copyTextToClipboard(text) {
					if(!this.clipboardAPIEnabled) {
						return;
					}

					navigator.clipboard.writeText(text);
				},

				$nl2br(text) {
					return (text || '').replace(/\r\n|\r|\n/g, '<br/>')
				},

				getFtgArbPlatsAdr({Adress1 = this.FtgInfo.Adress1, Adress2 = this.FtgInfo.Adress2} = {}) {
					const ArbPlatsFieldsMaxLength = 50;
					let ftgAdress1                = Adress1.trim();
					const ftgAdress2              = Adress2.trim();

					let FtgAdress = (ftgAdress1 + ftgAdress2).trim();

					if(ftgAdress1 && ftgAdress2) {
						const charactercountExceedingMax = (ftgAdress1.length + ftgAdress2.length + 2) - ArbPlatsFieldsMaxLength;

						if(charactercountExceedingMax > 0) {
							ftgAdress1 = ftgAdress1.slice(0, ftgAdress1.length - charactercountExceedingMax);
						}

						FtgAdress = [ftgAdress1, ftgAdress2].join(', ').trim();
					} else if(ftgAdress2 && !ftgAdress1) {
						FtgAdress = ftgAdress2;
					} else if(ftgAdress1 && !ftgAdress2) {
						FtgAdress = ftgAdress1;
					}

					return FtgAdress;
				},
			},

			destroyed() {
				const eventSourceHandlers = this.$eventSourceHandlers || {};
				const registeredEvents = Object.keys(eventSourceHandlers);

				for(const eventName of registeredEvents) {
					if(eventSourceHandlers[eventName]) {
						this.$eventBus.$off(`WebSocketClient:${eventName}`, eventSourceHandlers[eventName], this);
					}
				}
			},

			computed: {
				...mapState(['UserAccount', 'HasLicensOption', 'HasUserOption', 'FtgInfo']),

				ASYNC_WORK_CONCURRENCY() {
					return 3;
					// return 1;
				},

				SharedViewFilters: {
					get () {
						return sharedViewFilters;
					},

					set() {
						throw new Error('Set is not allowed to be called on SharedViewFilters');
					},
				},

				anyDialogsVisible() {
					return document.getElementsByClassName('v-dialog__content--active').length > 0;
				},

				activeElementIsInput() {
					return document.activeElement && document.activeElement.tagName === 'INPUT';
				},

				clipboardAPIEnabled() {
					return navigator && navigator.clipboard && navigator.clipboard.writeText;
				},

				hasPortal() {
					return ['PORTAL', 'POROLD', 'PORBAS', 'PORMAX'].find((Option) => this.HasLicensOption[Option]);
				},

				companyDumpRestoreEnabled() {
					const Login = _.get(store, 'state.UserAccount.Login', '');
					const LicNr = _.get(store, 'state.UserAccount.LicNr', '');

					return process.env.VUE_APP_RESTORE_COMPANY_COPY_ENABLED === 'true' &&
						!Login.toLowerCase().includes('dvsupport') &&
						(
							process.env.VUE_APP_RESTORE_COMPANY_COPY_ENABLED_FOR_LICNR === '*' ||
							(process.env.VUE_APP_RESTORE_COMPANY_COPY_ENABLED_FOR_LICNR || '').split(',').includes(LicNr)
						)
				},

				licLSSEnabled() {
					return this.HasLicensOption.LSSTIL;
				},

				ftgLSSEnabled() {
					return this.licLSSEnabled && store.state.FtgInfo.UseLSS;
				},

				userCanToggleLSSEnabledState() {
					return this.licLSSEnabled && this.HasUserOption.LSSTIL;
				},

				userLSSEnabled() {
					return this.ftgLSSEnabled && this.HasUserOption.LSSTIL;
				},

				isLonDemoUser() {
					return store.state.UserRights instanceof LonDemoUser;
				},

				NOLL_GUID() {
					return '00000000-0000-0000-0000-000000000000';
				},

				RECAPTCHA_SITEKEY() {
					return process.env.VUE_APP_RECAPTCHA_SITEKEY;
				},

				ELDReglerEnabled() {
					return process.env.VUE_APP_DEBUG === 'true' || this.UserAccount.LicNr === 'WEB999400';
				},
			},
		});
	},
};

export default DV;
